/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';
@import '~swiper/scss/pagination';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// http://ionicframework.com/docs/v2/theming/

// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

.toast__danger {
  --background: var(--danger);
  --color: var(--toast-text);
  --button-color: var(--toast-text);
}
.toast__primary {
  --background: var(--primary);
  --color: var(--toast-text);
  --button-color: var(--toast-text);
}
.toast-message {
  white-space: pre-wrap;
}

.setup-modal-container .modal-wrapper {
  width: 500px;
  height: 700px;
  left: calc(50% - (500px / 2));
  top: calc(50% - (700px / 2));
}

.segment-md .segment-button {
  color: var(--secondary-text-dark);
}

.slide-zoom {
  height: 100%;
}

.primary-action-btn {
  min-width: 200px;
  margin: 10px;
}

.secondary-action-btn {
  min-width: 200px;
  margin: 10px;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.justify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-margin {
  margin-left: 10px;
  margin-right: 0px;
}

// https://github.com/ionic-team/ionic/issues/6022
.content .scroll-content {
  overflow-y: auto;
}

.content-ios .scroll-content {
  overflow-y: scroll;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}
